import React, { useContext } from 'react';
import styles from './MainProgram.module.scss';
import { LanguageContext } from '../Context/LanguageContext';  // 引入LanguageContext

const MainProgram1 = () => {
  const { language } = useContext(LanguageContext);  // 获取当前语言状态

  return (
    <div className={styles.mainProgram}>
      <h2 className={styles.title}>{language === 'zh' ? '我们的主要项目' : 'Our Main Programs'}</h2>

      <div className={styles.contentWrapper}>
        <div className={styles.programSection}>
          <div className={styles.textSection}>
            <h3>{language === 'zh' ? '按摩师' : 'Massage Therapy'}</h3>
            <h4>2000 {language === 'zh' ? '小时' : 'Hours'} (50 {language === 'zh' ? '周)' : 'Weeks)'}</h4>
            <h4>{language === 'zh' ? '(实践与理论相结合的课程 + 学生临床实习)' : '(Practical & Theoretical hybrid classes+ student clinic)'}</h4>
            <ul>
              <li>{language === 'zh' ? '提供深入的学术和实践经验，帮助通过CMTO考试' : 'Offers in-depth academic and practical experience to CMTO exam'}</li>
              <li>{language === 'zh' ? '为加入医疗团队做好准备' : 'Prepares you to join a health care team'}</li>
              <li>{language === 'zh' ? '适合热衷于身心健康的人' : 'Ideal for those passionate about well-being'}</li>
              <li>{language === 'zh' ? '启动你的按摩治疗职业生涯' : 'Launches your career in massage therapy'}</li>
              <li>{language === 'zh' ? '为毕业生在水疗、度假村或独立工作做好准备' : 'Prepares graduates for spa, resort, or independent work '}</li>
              <li>{language === 'zh' ? '毕业生可在健康与保健中心、水疗、度假村、医疗水疗、健身俱乐部、游轮和家庭护理等场所工作' : 'Graduates can work in settings like health & wellness centers, spas, resorts, medical spas, fitness clubs, cruise ships, and home care'}</li>
            </ul>
            <h4>{language === 'zh' ? '毕业生可以在各种场所工作，例如：' : 'The graduates can work in a variety of settings like: '}</h4>
            <ul className={styles.jobOpportunity}>
              <li>{language === 'zh' ? '健康与保健' : 'Health & Wellness'}</li>
              <li>{language === 'zh' ? '水疗和度假村' : 'Spas and Resorts'}</li>
              <li>{language === 'zh' ? '医疗水疗' : 'Medical Spas'}</li>
              <li>{language === 'zh' ? '健身俱乐部' : 'Fitness Clubs'}</li>
              <li>{language === 'zh' ? '游轮' : 'Cruise Ships'}</li>
              <li>{language === 'zh' ? '居家护理' : 'Home Care'}</li>
            </ul>
          </div>
          <img src='/assets/images/main_program_2_1.svg' alt='Massage Therapy' className={styles.programImage}/>
        </div>

        <div className={styles.programSection}>
        <img src='/assets/images/main_program_1_1.svg' alt='Professional Culinary Arts' className={styles.programImage}/>
          <div className={styles.textSection}>
          <h3>{language === 'zh' ? '烹饪艺术专业' : 'Professional Culinary Arts'}</h3>
            <h4>800 {language === 'zh' ? '小时' : 'Hours'} (40 {language === 'zh' ? '周)' : 'Weeks)'}</h4>
            <h4>{language === 'zh' ? '(实践与理论相结合的课程 + 实习)' : '(Practical & Theoretical hybrid classes + internship)'}</h4>
            <ul>
              <li>{language === 'zh' ? '涵盖食品的准备、烹饪、摆盘、呈现和服务' : 'Covers preparation, cooking, plating, presentation, and serving of food'}</li>
              <li>{language === 'zh' ? '适合热爱美食的人' : 'Ideal for those passionate about food'}</li>
              <li>{language === 'zh' ? '为烹饪事业提供起点' : 'Serves as a springboard for a culinary career'}</li>
              <li>{language === 'zh' ? '为毕业生成为专业厨师做好准备' : 'Prepares graduates to become professional chefs'}</li>
            </ul>
            <h4>{language === 'zh' ? '毕业生可以在各种场所工作，例如：' : 'The graduates can work in a variety of settings like: '}</h4>
            <ul className={styles.jobOpportunity}>
              <li>{language === 'zh' ? '酒店' : 'Hotels'}</li>
              <li>{language === 'zh' ? '度假村' : 'Resorts'}</li>
              <li>{language === 'zh' ? '餐厅（从快餐到高级餐厅）' : 'Restaurants (from fast food to fine dining)'}</li>
              <li>{language === 'zh' ? '会议中心' : 'Conference centres'}</li>
              <li>{language === 'zh' ? '医院' : 'Hospitals'}</li>
              <li>{language === 'zh' ? '宴会厅和俱乐部' : 'Banquet halls and clubs'}</li>
              <li>{language === 'zh' ? '营地厨房' : 'Camp kitchens'}</li>
              <li>{language === 'zh' ? '全国范围的特色食品店' : 'Nationwide specialty food outlets'}</li>
            </ul>
          </div>
        </div>

        <div className={styles.programSection}>
          <div className={styles.textSection}>
            <h3>{language === 'zh' ? '个人护理员' : 'Personal Support Worker (PSW)'}</h3>
            <h4>700 {language === 'zh' ? '小时' : 'Hours'} (28 {language === 'zh' ? '周)' : 'Weeks)'}</h4>
            <h4>{language === 'zh' ? '(在线课程带薪实习)' : '(Online classes paid internship)'}</h4>
            <ul>
              <li>{language === 'zh' ? '为学生准备个人支持工作者（PSW）角色：' : 'Prepares students for Personal Support Worker (PSW) roles'}</li>
              <li>{language === 'zh' ? '重点关注照顾老年人、残疾人士和需要帮助的个体' : 'Focus on caring for elderly, disabled, and individuals in need'}</li>
              <li>{language === 'zh' ? '提供动手实践培训和实际经验' : 'Hands-on training and practical experience'}</li>
              <li>{language === 'zh' ? '在医疗保健领域需求旺盛' : 'High demand in healthcare settings'}</li>
              <li>{language === 'zh' ? '提供即时就业机会' : 'Immediate employment opportunities'}</li>
            </ul>
            <h4>{language === 'zh' ? '个人护理员课程的毕业生可以在各种医疗护理环境中找到令人满意的职业道路，包括：' : 'Graduates of the Personal Support Worker program find rewarding career paths in various healthcare settings, including:'}</h4>
            <ul className={styles.jobOpportunity}>
              <li>{language === 'zh' ? '护理院' : 'Nursing homes'}</li>
              <li>{language === 'zh' ? '家庭护理机构' : 'Home care agencies'}</li>
              <li>{language === 'zh' ? '临终关怀' : 'Hospices'}</li>
              <li>{language === 'zh' ? '康复中心' : 'Rehabilitation centers'}</li>
              <li>{language === 'zh' ? '辅助生活设施' : 'Assisted living facilities'}</li>
              <li>{language === 'zh' ? '精神健康设施' : 'Mental health facilities'}</li>
              <li>{language === 'zh' ? '社区卫生组织' : 'Community health organizations'}</li>
            </ul>
          </div>
          <img src='/assets/images/main_program_3_1.svg' alt='Personal Support Worker' className={styles.programImage}/>
        </div>
      </div>
    </div>
  );
};

export default MainProgram1;
