import { useContext, useState } from 'react';  
import { LanguageContext } from '../Context/LanguageContext';  
import axios from 'axios';
import styles from './Banner.module.scss';

const Banner = () => {
    const QRWechat = '/assets/images/QR_wechat.svg';  
    const QRPhone = '/assets/images/QR_phone.svg';    
    const apiUrl = 'https://test.tekoai.com/chatbotapi';
    const { language } = useContext(LanguageContext); 

    const [formData, setFormData] = useState({
        name: '',
        phone_num: '',
        email: '',
        notes: '',
        bot_id: 247
    });
    const [message, setMessage] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();  

        // 验证：电话和邮箱必须至少填写一个
        if (!formData.phone_num && !formData.email) {
            setMessage(language === 'zh' ? '请至少填写电话或邮箱' : 'Please fill in either phone number or email');
            return;
        }

        try {
            const response = await axios.post(`${apiUrl}/addWebBuildContact/addWebBuildContact`, formData);  
            if (response.status === 200) {
                setMessage(language === 'zh' ? '表单提交成功！' : 'Form submitted successfully!');
                setFormData({ name: '', phone_num: '', email: '', notes: '', bot_id: 247 });  
            }
        } catch (error) {
            console.error('Error submitting the form:', error);
            setMessage(language === 'zh' ? '表单提交失败，请稍后再试。' : 'Form submission failed, please try again later.');
        }
    };

    // Handle scroll to Banner
    const handleScrollToBanner = () => {
        const bannerElement = document.getElementById('form-section');
        if (bannerElement) {
        const offset = 100; // 假设导航栏高度为100px
        const elementPosition = bannerElement.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - offset;
    
        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
        });
        }
    };

    return (
        <div className={styles.bannerWrapper}>
            <div className={styles.banner} id="banner">
                <div className={styles.leftSection}>
                    <div className={styles.content}>
                    <div className={styles.benefits}>
                        <h2>{language === 'zh' ? '金融援助' : 'Financial Support'}</h2>
                        <ul>
                            <li>{language === 'zh' ? '加拿大学生有资格获得财政援助' : 'Canadian students may be eligible for financial assistance'}</li>
                            <li>{language === 'zh' ? '学院提供延期付款计划' : 'Deferred payment plans are offered by the college'}</li>
                            <li>{language === 'zh' ? '安省技工学院（OCOT）每年提供20%到40%的奖学金' : 'OCOT offers 20% to 40% scholarships every year'}</li>
                        </ul>
                        <div className={styles.buttons}>
                            <button className={styles.applyButton} onClick={handleScrollToBanner}>
                                {language === 'zh' ? '立即申请' : 'Apply Now'}
                            </button>
                            <button
                                className={styles.videoButton}
                                onClick={() => window.open('https://youtu.be/Wfg1Sur2Zvc?si=qhKWhWvCXIReF2rn', '_blank')}>
                                📹{language === 'zh' ? '观看介绍' : 'Watch Video'}
                            </button>
                        </div>
                    </div>
                        <div className={styles.benefits}>
                            <h2>{language === 'zh' ? '安省更好工作计划为符合条件的申请人提供哪些福利？' : 'What Benefits Does Better Jobs Ontario Offer Eligible Applicants?'}</h2>
                            <ul>
                                <li>{language === 'zh' ? '全额学费' : 'Full tuition'}</li>
                                <li>{language === 'zh' ? <>基本生活津贴（每周最高500元）<span className={styles.icon}>②</span></> : <>Basic living allowance ($500/week)<span className={styles.icon}>②</span></>}</li>
                                <li>{language === 'zh' ? '书籍费用' : 'Book fees'}</li>
                                <li>{language === 'zh' ? '其他教育费用（包括学习材料和电子设备）' : 'Other educational expenses (including study materials and electronic devices)'}</li>
                                <li>{language === 'zh' ? '交通费用' : 'Transportation fees'}</li>
                                <li>{language === 'zh' ? '额外金额：育儿，残疾相关支持，住宿等' : 'Additional amount for: childcare, disability-related support, accommodation, etc.'}</li>
                            </ul>
                        </div>
                        <div className={styles.eligibility}>
                            <h2>{language === 'zh' ? '谁可以申请？' : 'Who Can Apply?'}</h2>
                            <p>{language === 'zh' ? '如果您满足以下任何条件，则符合申请：' : 'You may be eligible to apply if you meet any of the following conditions:'}</p>
                            <ul>
                                <li>{language === 'zh' ? '因工作短缺被解雇（ROE A类），目前处于失业状态或临时工作。' : 'You have been laid-off and be unemployed.'}</li>
                                <li>{language === 'zh' ? '已经失业6个月或更长时间，并且属于低收入家庭。' : 'You are from a low-income household and experiencing challenges attaching to the labour market'}</li>
                            </ul>
                        </div>
                        <div className={styles.qrSection}>
                            <h2>{language === 'zh' ? '如何联系我们？' : 'How To Contact Us?'}</h2>
                            <div className={styles.qrCodes}>
                                <img src={QRWechat} alt="QR WeChat" />
                                <img src={QRPhone} alt="QR Phone" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.rightSection} id="form-section">
                    <div className={styles.formWrapper}>
                        <h2>{language === 'zh' ? '预约免费信息咨询，请致电 416-332-8727。或填写以下表格：' : 'To book a free trial or information session, please call 416-332-8727. Or fill out the following form: '}</h2>
                        <form onSubmit={handleSubmit}>
                            <label>{language === 'zh' ? '姓名' : 'Name'}</label>
                            <input 
                                type="text" 
                                name="name" 
                                value={formData.name} 
                                onChange={handleInputChange} 
                                placeholder={language === 'zh' ? '请输入您的姓名' : 'John Doe'} 
                                required 
                            />
                            <label>{language === 'zh' ? '电话' : 'Phone'}</label>
                            <input 
                                type="tel" 
                                name="phone_num" 
                                value={formData.phone_num} 
                                onChange={handleInputChange} 
                                placeholder={language === 'zh' ? '请输入您的电话' : '123-456-7890'}
                            />
                            <label>{language === 'zh' ? '邮箱' : 'Email'}</label>
                            <input 
                                type="email" 
                                name="email" 
                                value={formData.email} 
                                onChange={handleInputChange} 
                                placeholder={language === 'zh' ? '请输入您的邮箱' : 'johndoe@mail.com'}
                            />
                            <label>{language === 'zh' ? '留言' : 'Message'}</label>
                            <textarea 
                                name="notes" 
                                value={formData.notes} 
                                onChange={handleInputChange} 
                                placeholder={language === 'zh' ? '请留言' : 'Message'}
                                required
                            ></textarea>
                            <button type="submit">{language === 'zh' ? '立即申请' : 'Apply Now'}</button>
                        </form>
                        {message && <p>{message}</p>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;
