import './App.css';
import Header from './components/Header';
import Banner from './components/Banner';
import Feature from './components/Feature';
import WhyChoose from './components/WhyChoose';
import Footer from './components/Footer';
import Map from './components/Map';
import Float from './components/Float';
import HeroSection from './components/HeroSection';
import MiniProgram from './components/MiniProgram';
import MainPrograms from './components/MainPrograms';
import StudentsReviews from './components/StudentsReviews';
import { useWindowScrollPositions } from './hooks/useWindowScrollPositions';
import { useIsMobile } from './hooks/useIsMobile';
import { LanguageProvider } from './components/Context/LanguageContext'; 

function App() {
  const { scrollY } = useWindowScrollPositions();

  return (
    <LanguageProvider>
      <div className="App">
        <Header />
        <HeroSection />
        <Banner />
        <Feature />
        <MainPrograms />
        <MiniProgram />
        <WhyChoose />
        <StudentsReviews />
        <Map />
        <Footer />
        
        {/* Conditional rendering for Float based on scroll and device */}
        {!useIsMobile() && scrollY > 100 ? <Float /> : null}
        {useIsMobile() && scrollY > 560 ? <Float /> : null}
      </div>
    </LanguageProvider>
  );
}

export default App;
