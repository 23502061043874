import React, { useContext } from 'react';  // 引入 useContext
import { LanguageContext } from '../Context/LanguageContext';  // 引入LanguageContext
import styles from './MiniProgram.module.scss';

const programs = {
  en: [
    {
      title: "Quality Assurance",
      duration: "Program Duration: 1 year",
      hours: ["Total Hours: 800 hours"],
      description: "",
      classes:["Must-have diploma for quality professionals",
        "Covers essential knowledge and skills for the field",
        "Prepares students for ASQ certifications (CQE, CQA, CSSBB, etc.)"
      ],
      image: "/assets/images/mini_program_1.svg"
    },
    {
      title: "Esthetics",
      duration: "Program Duration: 1 year",
      hours: ["Total Hours: 1040 hours", "Theory: 490 hours", "Practice: 510 hours", "Internship: 40 hours"],
      description: "",
      classes:["Equip individuals for careers in Esthetics",
        "Learn skin care, human anatomy, nutrition, and the importance of bacteriology and decontamination",
        "Gain skills in nail technology, skin care, manicures & pedicures, make-up artistry, body treatments, and facials",
        "Cover business management skills for salons and spas",
        "Basic computer skills are included"
      ],
      image: "/assets/images/mini_program_2.svg"
    },
    {
      title: "Medical Esthetics",
      duration: "Program Duration: 1 year",
      hours: ["Total Hours: 1450 hours", "Theory: 780 hours", "Practice: 630 hours", "Internship: 40 hours"],
      image: "/assets/images/mini_program_3.svg"
    },
    {
      title: "Business Management",
      duration: "Program Duration: 1 year",
      hours: ["Total Hours: 980 hours"],
      description: "Theoretical course + practical project",
      image: "/assets/images/mini_program_4.svg"
    },
    {
      title: "IBM - Finance",
      duration: "Program Duration: 1 year",
      hours: ["Total Hours: 960 hours"],
      description: "Theoretical course + practical project",
      image: "/assets/images/mini_program_5.svg"
    },
    {
      title: "Early Childhood Assistant",
      duration: "Program Duration: 1 year",
      hours: ["Total Hours: 1000 hours", "Theoretical Courses: 675 hours", "Off-campus Internship: 325 hours"],
      description: "The plan aims to provide childcare providers with the necessary knowledge, skills, and abilities to serve as Early Childhood Assistants (ECA).",
      classes:["Equips childcare workers for Early Childhood Assistant (ECA) roles",
        "Popular program",
        "Easy employment",
        "High starting salary",
        "Job stability",
        "No exams required",
        "Valuable ECA practicum experience"
      ],
      image: "/assets/images/mini_program_6.svg"
    },
    {
      title: "Acupuncture",
      duration: "Program Duration: 1-2 years",
      hours: ["Total Hours: 1960 hours"],
      description: "Complete Chinese language instruction, with comprehensive mastery of traditional Chinese medicine theory. Practical experience from multiple doctors of traditional Chinese medicine in clinics. Pre-exam tutoring to ensure a high passing rate. Students with prior knowledge may be exempt from certain courses.",
      classes:["Comprehensive Diploma in Acupuncture program",
        "Combines theoretical knowledge and practical skills",
        "Rooted in Traditional Chinese Medicine",
        "Prepares students to become registered acupuncturists",
        "Graduates eligible for Pan-Canadian (PAN-CAN) exams"
      ],
      image: "/assets/images/mini_program_7.svg"
    },
    {
      title: "Data Analytics and Business Intelligence",
      duration: "Program Duration: 1 year",
      hours: ["Total Hours: 740 hours"],
      description: "Provide training on a modern set of analytical hard skills and tools, including but not limited to RDBMS, Hadoop, Cloud, Hive, Impala, Spark, Python, R, SAS, Tableau, Power BI, Excel, etc.",
      career: "Career paths: Data Analyst, Data Scientist, Data Reporting Analyst, etc.",
      classes:["Learn to install, configure, and manage Microsoft SQL Server, including troubleshooting",
        "Gain skills in programming databases and using Transact-SQL to implement and manage database objects",
        "Cover essential concepts in probability models, distributions, and statistical methods with practical applications",
        "Develop and debug SAS programs for data analysis, focusing on effective manipulation techniques",
        "Understand the planning, design, and maintenance of data warehouses for decision support",
        "Explore methods for extracting knowledge from data, including decision trees, neural networks, and text mining",
        "Learn best practices for designing and implementing Business Intelligence solutions"
      ],
      image: "/assets/images/mini_program_8.svg"
    },
    {
      title: "Computer Network Technology",
      duration: "Program Duration: 1 year",
      hours: ["Total Hours: 800 hours"],
      description: "Computer network technicians establish, operate, maintain, upgrade, repair, troubleshoot, and coordinate the use of local area networks (LANs) and wide area networks (WANs) (both wired and wireless) from both hardware and software perspectives, involving mainframe networks and related network equipment. The program also helps students prepare for CompTIA A+ and CCNA certification.",
      classes:["Establish, operate, and maintain LANs, WANs, and mainframe networks",
        "Set up and manage Internet and Intranet websites, hardware, and software",
        "Monitor and optimize network performance and connectivity",
        "Prepares students for A+, MCSE, and CCNA certifications",
        "Employed in IT units across private and public sectors",
        "Supervisors of computer network technicians are part of this group",
        "Example job titles include LAN administrator, Web technician, network administrator, and system administrator; the course covers PC hardware, operating systems, network fundamentals, TCP/IP, Microsoft Windows networking, Unix administration, and Cisco routing"
      ],
      image: "/assets/images/mini_program_9.svg"
    }
  ],
  zh: [
    {
      title: "质量管控",
      duration: "持续时间：1 年",
      hours: ["总时数: 800 小时"],
      description: "",
      classes:["质量专业人员必备的文凭",
        "涵盖该领域的基本知识和技能",
        "为ASQ认证（CQE、CQA、CSSBB等）做好准备"
      ],
      image: "/assets/images/mini_program_1.svg"
    },
    {
      title: "美容",
      duration: "学制：1 年",
      hours: ["总时数: 1040 小时", "理论： 490 小时", "实践：510 小时", "实习：40 小时"],
      description: "",
      classes:["为美容职业生涯做好准备",
        "学习皮肤护理、人类解剖学、营养以及细菌学和消毒的重要性",
        "获得指甲技术、皮肤护理、美甲和美足、化妆艺术、身体护理和面部护理的技能",
        "涵盖美容院和水疗中心的商业管理技能",
        "包括基本计算机技能"
      ],
      image: "/assets/images/mini_program_2.svg"
    },
    {
      title: "医美",
      duration: "学制: 1年",
      hours: ["总时数: 1450 小时", "理论: 780 小时", "实践: 630 小时", "实习: 40 小时"],
      image: "/assets/images/mini_program_3.svg"
    },
    {
      title: "商业管理",
      duration: "持续时间: 1年",
      hours: ["总时数: 980 小时"],
      description: "理论课+实践项目",
      image: "/assets/images/mini_program_4.svg"
    },
    {
      title: "国际商业管理（研究生文凭）",
      duration: "持续时间: 1年",
      hours: ["总时数: 960 小时"],
      description: "理论课+实践项目",
      image: "/assets/images/mini_program_5.svg"
    },
    {
      title: "幼儿助理 (ECA) 早教",
      duration: "学制： 1 年",
      hours: ["总时数: 1000 小时", "理论课：675 小时", "校外实习：325 小时"],
      description: "该计划旨在为儿童保育员提供所需的知识 ,担任幼儿助理 (ECA)的技能和能力。",
      classes:["为美容职业生涯做好准备",
        "为早期儿童助理（ECA）角色的儿童护理工作者提供培训",
        "受欢迎的项目",
        "容易就业",
        "高起薪",
        "工作稳定",
        "无需考试",
        "具有宝贵的ECA实践经验"
      ],
      image: "/assets/images/mini_program_6.svg"
    },
    {
      title: "针灸",
      duration: "持续时间：1-2 年",
      hours: ["总时数: 1960 小时"],
      description: "全中文教学,详尽掌握 中医理论多名中医博士诊所实战经验考前辅导,保证高通过率有基础的同学,可免修部分课程",
      classes:["综合针灸文凭课程",
        "结合理论知识和实践技能",
        "根植于传统中医学",
        "准备学生成为注册针灸师",
        "毕业生有资格参加全加（PAN-CAN）考试"
      ],
      image: "/assets/images/mini_program_7.svg"
    },
    {
      title: "数据分析和商业智能",
      duration: "持续时间: 1 年",
      hours: ["总时数: 740 小时"],
      description: "以下是关于现代分析硬技能和工具的培训，包括但不限于 RDBMS、Hadoop、云计算、Hive、Impala、Spark、Python、R、SAS、Tableau、Power BI、Excel 等。",
      career:"职业路径：数据分析师、数据科学家、数据报告分析师等。",
      classes:["学习安装、配置和管理Microsoft SQL Server，包括故障排除",
        "获得编程数据库和使用Transact-SQL来实现和管理数据库对象的技能",
        "涵盖概率模型、分布和统计方法的基本概念，并进行实际应用",
        "开发和调试SAS程序以进行数据分析，重点关注有效的数据操作技术",
        "了解数据仓库的规划、设计和维护，以支持决策",
        "探索从数据中提取知识的方法，包括决策树、神经网络和文本挖掘",
        "学习设计和实施商业智能解决方案的最佳实践"
      ],
      image: "/assets/images/mini_program_8.svg"
    },
    {
      title: "计算机网络技术",
      duration: "持续时间: 1 年",
      hours: ["总时数: 800 小时"],
      description: "计算机网络技术人员从硬件和软件两个方面建立、操作、维护、升级、修理、修复、故障排除和协调局域网和广域网 (有线或无线)的使用,涉及大型机网络和相关网络设备。该计划还帮助学生为通过CompTIA A+和CCNA认证做好准备。",
      classes:["建立、操作和维护局域网（LAN）、广域网（WAN）和大型机网络",
        "设置和管理互联网和内联网网站、硬件和软件",
        "监控和优化网络性能和连接性",
        "为A+、MCSE和CCNA认证做好准备",
        "在私营和公共部门的IT部门工作",
        "计算机网络技术员的主管属于此类别",
        "示例职位包括局域网管理员、网页技术员、网络管理员和系统管理员；课程涵盖PC硬件、操作系统、网络基础、TCP/IP、Microsoft Windows网络、Unix管理和Cisco路由"
      ],
      image: "/assets/images/mini_program_9.svg"
    }
  ]
};

const MiniProgram = () => {
    const { language } = useContext(LanguageContext);  // 获取语言状态

    return (
        <div className={styles.miniProgramContainer}>
            <h2>{language === 'zh' ? '我们的其他项目' : 'Our Other Programs'}</h2>
            <div className={styles.programGrid}>
                {programs[language].map((program, index) => (
                    <div key={index} className={styles.programCard}>
                        <div className={styles.programImageContainer}>
                            <img src={program.image} alt={program.title} className={styles.programImage} />
                        </div>
                        <div className={styles.programContent}>
                            <h3>{program.title}</h3>
                            <p>{program.duration}</p>
                            {program.hours.map((hour, index) => (
                              <p key={index}>{hour}</p>
                            ))}
                            <h4>{program.description}</h4>
                            <h4>{program.career}</h4>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MiniProgram;
