import { useContext } from 'react';  // 引入 useContext
import { LanguageContext } from '../Context/LanguageContext';  // 引入LanguageContext
import styles from './Feature.module.scss';

const Feature = () => {
    const { language } = useContext(LanguageContext);  // 获取语言状态

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>
                {language === 'zh' ? '安省理工学院（OCOT）简介' : 'Ontario College of Technology (OCOT) Overview'}
            </h1>
            <p className={styles.text}>
                {language === 'zh'
                    ? '安省理工学院是一所享有盛誉的理工学院，位于大多伦多地区，提供包括文凭、职业培训、在线课程和企业教育在内的多种课程。'
                    : 'The Ontario Institute of Technology is a prestigious comprehensive polytechnic institution located in the Greater Toronto Area of Canada. The institute boasts strong capabilities, advanced equipment, a powerful faculty, and a good reputation. It offers a range of programs including diploma education, vocational training, online distance education, and on-site corporate training.'
                }
            </p>
            <p className={styles.text}>
                {language === 'zh'
                    ? '作为《职业学院法》（2005）下的注册私人职业学院，我们得到了加拿大政府的认可。同时，我们也是一所指定学习机构，获准招收本地及国际学生。'
                    : 'As a registered Private Career College under the Ontario Private Career Colleges Act (2005), we are recognized by the Canadian government. We are also a Designated Learning Institution, authorized to enroll both local and international students.'
                }
            </p>
            <p className={styles.text}>
                {language === 'zh'
                    ? '我们的承诺是提供高质量的教育和实践经验，并为毕业生提供就业机会。'
                    : 'Our commitment is to deliver top-quality education and hands-on experience, along with providing placement opportunities to students after graduation.'
                }
            </p>
            <p className={styles.motto}>
                {language === 'zh'
                    ? '安省理工学院的校训是：' 
                    : 'The institute\'s motto is:'
                } 
                <span className={styles.mottoHighlight}>
                    {language === 'zh' ? '止于至善' : '"To strive for excellence."'}
                </span>
            </p>
        </div>
    );
};

export default Feature;
