import React, { useState, useEffect, useContext } from 'react';
import styles from './HeroSection.module.scss';
import { LanguageContext } from '../Context/LanguageContext'; // Import the Language Context

const HeroSection = () => {
  const { language } = useContext(LanguageContext); // Access the language from the context
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [
    '/assets/images/header_1.svg',
    '/assets/images/header_2.svg',
    '/assets/images/header_3.svg',
    '/assets/images/header_4.svg',
    '/assets/images/header_5.svg',
  ];

  // Automatically switch images every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval); // Clear interval on unmount
  }, [images.length]);

  const handleDotHover = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className={styles.heroSection}>
      <div className={styles.textContent}>
        {language === 'zh' ? (
          <>
            <h1 className={styles.title}>
            获得高达 $28,000 的政府支持！<span className={styles.icon}>①</span>
            </h1>
            <h1 className={styles.extraMargin}>就业有保障！</h1>
            <q>提供最受欢迎和实用的课程</q>
            <p className={styles.extraMargin}>OCOT 可以帮助你获得成功所需的重要技能</p>
          </>
        ) : (
          <>
            <h1 className={styles.title}>
            Opportunity to Receive Up to $28,000 in Government Support! <span className={styles.icon}>①</span> 
            </h1>
            <h1 className={styles.extraMargin}>Better Job Opportunities!</h1>
            <q>Providing The Most Popular And Practical Programs</q>
            <p className={styles.extraMargin}>OCOT Can Help You Gain The Important Skills You Need To Succeed</p>
          </>
        )}
      </div>
      <div className={styles.imageContainer}>
        <img
          src={images[currentIndex]}
          alt={`Slide ${currentIndex + 1}`}
          className={styles.slideshowImage}
        />
        <div className={styles.dots}>
          {images.map((_, index) => (
            <span
              key={index}
              className={`${styles.dot} ${currentIndex === index ? styles.active : ''}`}
              onMouseEnter={() => handleDotHover(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
